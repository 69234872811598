import React, { useState } from "react";
import { IconButton, Tooltip, Box, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {useChatSessionContext} from "../../../shared/contexts/UserContext";

interface SessionsIconsListProps {
    bot_id: string;
    active_session: string;
    onEntryEnabledChange: (enabledEntryId: string) => void;
    createNewSession: () => void;
    contrastColor: string;
}

const SessionsIconsList: React.FC<SessionsIconsListProps> = (props: SessionsIconsListProps) => {

    const { chatSessions, removeChatSession } = useChatSessionContext();

    const [enabledEntry] = useState<string>("");
    const [menuAnchorEl, setMenuAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});

    const handleToggleEnable = (entry: string) => {
        // setEnabledEntry(entry);
        props.onEntryEnabledChange(entry);
    };

    const handleDelete = (entry: string) => {
        console.log(`Delete requested for: ${entry}`);
        removeChatSession(entry)
    };

    const getIconByKey = (key: string) => {
        const emojis = localStorage.getItem(key + "/icon")
        if (emojis) {
            return Array.from(emojis)[0];     
        } else {
            return "?"
        }
    };

    const getFullIconByKey = (key: string) => {
        const emojis = localStorage.getItem(key + "/icon")
        if (emojis) {
            return emojis
        } else {
            return "?"
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, entry: string) => {
        setMenuAnchorEl((prev) => ({ ...prev, [entry]: event.currentTarget }));
    };

    const handleMenuClose = (entry: string) => {
        setMenuAnchorEl((prev) => ({ ...prev, [entry]: null }));
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center", // Center items horizontally
                alignItems: "center", // Center items vertically
                gap: 1,
            }}
        >
            {chatSessions.map((sessionId) => {
                if (!sessionId.startsWith("/bbSession/" + props.bot_id )) {
                    return <></>
                }
                return (
                    <Box key={sessionId}>
                        <Tooltip title={getFullIconByKey(sessionId)} placement="top" arrow sx={{
                            "& .MuiTooltip-tooltip": {
                                fontSize: "30px", // Change the font size
                            }
                        }}>
                            <IconButton
                                onClick={() => handleToggleEnable(sessionId)}
                                onContextMenu={(e) => {
                                    e.preventDefault(); // Prevent default right-click menu
                                    handleMenuOpen(e, sessionId);
                                }}
                                sx={{
                                    border: `1px solid ${props.contrastColor}`,
                                    backgroundColor: sessionId.includes(props.active_session) ? props.contrastColor : "white.300",
                                    color: "white",
                                    display: "flex", // Ensures the content can be flex-aligned
                                    alignItems: "center", // Vertically center the content
                                    justifyContent: "center", // Horizontally center the content
                                    padding: 0, width: '28px', height: '28px',
                                    transition: "transform 0.2s",
                                    "&:hover": {
                                        transform: "scale(1.05)",
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "70%",  // Scale icon within the button
                                        height: "70%", // Maintain aspect ratio
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: sessionId.includes(props.active_session) ? "white" : props.contrastColor,
                                    }}
                                >
                                    {getIconByKey(sessionId)}
                                </Box>
                            </IconButton>
                        </Tooltip>
    
                        <Menu
                            anchorEl={menuAnchorEl[sessionId]}
                            open={Boolean(menuAnchorEl[sessionId])}
                            onClose={() => handleMenuClose(sessionId)}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <MenuItem onClick={() => handleToggleEnable(sessionId)}>
                                <ListItemIcon>
                                    {enabledEntry === sessionId ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />}
                                </ListItemIcon>
                                <ListItemText>
                                    {enabledEntry === sessionId ? "Deactivate" : "Activate"}
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => handleDelete(sessionId)}>
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
            )}
            
            <Box key={"create_new_session_12324"}>
                <Tooltip title={"New session"} placement="top" arrow>
                    
                    <IconButton
                        onClick={() => {
                            // setEnabledEntry("")
                            props.createNewSession()
                        }}
                        sx={{
                            border: `0px solid ${props.contrastColor}`,
                            backgroundColor: "white.300",
                            color: "white",
                            display: "flex", // Ensures the content can be flex-aligned
                            alignItems: "center", // Vertically center the content
                            justifyContent: "center", // Horizontally center the content
                            padding: 0, width: '28px', height: '28px',
                            transition: "transform 0.2s",
                            "&:hover": {
                                transform: "scale(1.05)",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: "70%",  // Scale icon within the button
                                height: "70%", // Maintain aspect ratio
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: props.contrastColor,
                            }}
                        >
                            {"＋"}
                        </Box>
                    </IconButton>
                </Tooltip>
            </Box>
            
        </Box>
    );

};

export default SessionsIconsList;
